let initialTiles = [
  { value: "red", color: "#8F282A", matched: false },
  { value: "green", color: "#228D66", matched: false },
  { value: "blue", color: "#267DD5", matched: false },
  { value: "yellow", color: "#BEA008", matched: false },
  { value: "black", color: "#222222", matched: false },
  { value: "red", color: "#8F282A", matched: false },
  { value: "green", color: "#228D66", matched: false },
  { value: "blue", color: "#267DD5", matched: false },
  { value: "yellow", color: "#BEA008", matched: false },
  { value: "black", color: "#222222", matched: false },
  { value: "red", color: "#8F282A", matched: false },
  { value: "green", color: "#228D66", matched: false },
  { value: "blue", color: "#267DD5", matched: false },
  { value: "yellow", color: "#BEA008", matched: false },
  { value: "black", color: "#222222", matched: false },
  { value: "red", color: "#8F282A", matched: false },
  { value: "green", color: "#228D66", matched: false },
  { value: "blue", color: "#267DD5", matched: false },
  { value: "yellow", color: "#BEA008", matched: false },
  { value: "black", color: "#222222", matched: false },
  { value: "red", color: "#8F282A", matched: false },
  { value: "green", color: "#228D66", matched: false },
  { value: "blue", color: "#267DD5", matched: false },
  { value: "yellow", color: "#BEA008", matched: false },
  { value: "black", color: "#222222", matched: false },
];

let diceColors = ["#8F282A", "#228D66", "#267DD5", "#BEA008", "#222222"];

module.exports = {
  initialTiles,
  diceColors,
};

import React from "react";
import "./Radar.css";

export default function () {
  return (
    <div>
      <ul className="radar">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
    </div>
  );
}
